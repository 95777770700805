import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Registration Form";
const server = null;
const service = null;
const options = {
  industrytype: [
    {
      name: "Arts and Recreations Services",
      value: "Arts and Recreations Services",
    },
    {
      name: "Accommodation and Food Services",
      value: "Accommodation and Food Services",
    },
    {
      name: "Administrative and Support Services",
      value: "Administrative and Support Services",
    },
    {
      name: "Agriculture, Forestry and Fishing",
      value: "Agriculture, Forestry and Fishing",
    },
    { name: "Construction", value: "Construction" },
    { name: "Education and Training", value: "Education and Training" },
    {
      name: "Electricity, Gas, Water and Waste Services",
      value: "Electricity, Gas, Water and Waste Services",
    },
    {
      name: "Information Media and Telecommunications",
      value: "Information Media and Telecommunications",
    },
    {
      name: "Health Care and Social Assistance",
      value: "Health Care and Social Assistance",
    },
    {
      name: "Financial and Insurance Services",
      value: "Financial and Insurance Services",
    },
    {
      name: "Professional, Scientific and Technical Services",
      value: "Professional, Scientific and Technical Services",
    },
    { name: "Mining", value: "Mining" },
    { name: "Manufacturing", value: "Manufacturing" },
    {
      name: "Rental, Hiring and Real Estate Services",
      value: "Rental, Hiring and Real Estate Services",
    },
    {
      name: "Public Administration and Safety",
      value: "Public Administration and Safety",
    },
    { name: "Retail Trade", value: "Retail Trade" },
    {
      name: "Transport, Postal and Warehousing",
      value: "Transport, Postal and Warehousing",
    },
    { name: "Wholesale Trade", value: "Wholesale Trade" },
    { name: "Personal Services", value: "Personal Services" },
  ],
  referralSource: [
    {
      name: "Centre Referral - Albany",
      value: "Centre Referral - Albany",
    },
    {
      name: "Centre Referral - Manukau",
      value: "Centre Referral - Manukau",
    },
    {
      name: "Centre Referral - Newmarket",
      value: "Centre Referral - Newmarket",
    },
    {
      name: "Centre Referral - Riccarton",
      value: "Centre Referral - Riccarton",
    },
    {
      name: "Centre Referral - St. Lukes",
      value: "Centre Referral - St. Lukes",
    },
    {
      name: "In-centre advertising screens",
      value: "In-centre advertising screens",
    },
    {
      name: "Direct Email",
      value: "Direct Email",
    },
    {
      name: "Search Engine (Google, other websites, blogs etc)",
      value: "Search Engine (Google, other websites, blogs etc)",
    },
    {
      name: "Social Media",
      value: "Social Media",
    },
    {
      name: "Internet Advertising",
      value: "Internet Advertising",
    },
    // {
    //   name: "Administrative and Support Services",
    //   value: "Administrative and Support Services",
    // },
    {
      name: "Radio",
      value: "Radio",
    },
    { name: "Television", value: "Television" },
    // {
    //   name: "Streaming Service Ad",
    //   value: "Streaming Service Ad",
    // },
    {
      name: "Publication (Magazines or Newspapers)",
      value: "Publication (Magazines or Newspapers)",
    },
    // {
    //   name: "Health Care and Social Assistance",
    //   value: "Health Care and Social Assistance",
    // },
    // {
    //   name: "Financial and Insurance Services",
    //   value: "Financial and Insurance Services",
    // },
    // {
    //   name: "Billboard",
    //   value: "Billboard",
    // },
    {
      name: "Mail Out Flyers",
      value: "Mail Out Flyers",
    },
    {
      name: "Word of Mouth (Friend, Family or Colleagues)",
      value: "Word of Mouth (Friend, Family or Colleagues)",
    },
    // { name: "Referral", value: "Referral" },
    {
      name: "Other (Please specify)",
      value: "Other (Please specify)",
    },
  ],
};

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          register: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/registration",
            method: "PUT",
          },
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/auth/token",
            method: "POST",
          },
          search: {
            headers: {
              "Content-Type": "application/json",
            },
            // url: "https://nzbn.tr4ns.com/entities/search",
            // url: `${setup.setup.api.tranxactor_service.url[environment]}/tx-nzbn/loginAPI`,
            url: `https://nzbn.tranxactor.com/entities/search`,
            method: "GET",
          },
          // searchaddy: {
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   doc: "https://api.addy.co.nz",
          //   url: "https://api.addy.co.nz/search",
          //   method: "GET",
          // },
          // searchaddywithid: {
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   doc: "https://api.addy.co.nz",
          //   url: "https://api.addy.co.nz/address",
          //   method: "GET",
          // },
          searchaddy: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "{{nzbn-host}}/address/search?s=Queen street",
            url: "https://nzbn.tranxactor.com/address/search",
            method: "GET",
          },
          searchaddywithid: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "{{nzbn-host}}/address?id=1872765",
            url: "https://nzbn.tranxactor.com/address",
            method: "GET",
          },
        },
      },
      objects: {
        companyname: {
          name: "companyname",
          initial: "",
          label: "Company Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        nzbn: {
          name: "nzbn",
          initial: "",
          label: "NZBN",
          placeholder: "Search with your business number",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
        firstname: {
          name: "firstname",
          initial: "",
          label: "First Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        lastname: {
          name: "lastname",
          initial: "",
          label: "Last Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        contactno: {
          name: "contactno",
          initial: "",
          label: "Contact No",
          helperText: false,
          rules: ["isNotNull"],
        },
        mobileno: {
          name: "mobileno",
          initial: "",
          label: "Mobile No",
          helperText: false,
          rules: ["isNotNull"],
        },
        jobtitle: {
          name: "jobtitle",
          initial: "",
          label: "Job Title",
          helperText: false,
          rules: false,
        },
        gdpr: {
          name: "gdpr",
          initial: "",
          label: "",
          rules: false,
        },
        industrytype: {
          name: "industrytype",
          initial: "",
          label: "Industry Type",
          helperText: false,
          options: options.industrytype,
          rules: ["isNotNull"],
        },
        referralSource: {
          name: "referralSource",
          initial: "",
          label: "How Did You Hear About Us?",
          helperText: false,
          options: options.referralSource,
          rules: ["isNotNull"],
        },
        referralSourceOther: {
          name: "referralSourceOther",
          initial: "",
          label: "Please specify other ...",
          helperText: false,
          rules: false,
        },
        email: {
          name: "email",
          initial: "",
          label: "Email",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        password: {
          name: "password",
          initial: "",
          label: "Password",
          helperText: false,
          rules: ["isNotNull", "isPasswordStrength"],
        },
        passwordconfirm: {
          name: "passwordconfirm",
          initial: "",
          label: "Confirm password",
          helperText: false,
          rules: ["isNotNull", "isConfirm"],
          confirm_to: "password",
          confirm_suffix: "password",
        },

        address1: {
          name: "address1",
          initial: "",
          label: "Street Number & Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        suburb: {
          name: "suburb",
          initial: "",
          label: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        city: {
          name: "city",
          initial: "",
          label: "City",
          helperText: false,
          rules: ["isNotNull"],
        },
        // state: {
        //   name: "state",
        //   initial: "",
        //   type: "select",
        //   label: "State",
        //   options: options.preference_options,
        //   helpText: "Please select a preference",
        //   rules: ["isNotNull"]
        // },
        postcode: {
          name: "postcode",
          initial: "",
          label: "Post Code",
          helperText: false,
          rules: ["isNotNull"],
        },
        floor: {
          name: "floor",
          initial: "",
          label: "Floor",
          helperText: false,
          rules: false,
        },
        unitnumber: {
          name: "unitnumber",
          initial: "",
          label: "Unit Number",
          helperText: false,
          rules: false,
        },

        tandc: { name: "tandc", initial: "", label: "", rules: false },
      },
      responses: {
        fail: "No such username or password",
        unverified: "Please verify your account by ticking the recaptcha.",
        emailexist: "The email already exist.",
        tandcaccept: "Terms and Conditions should be agreed",
        traderexist: "NZBN already registered.",
      },
    },
  },
  routes: {
    label: "Registration Form",
    prefix: "/corporate/register/form",
  },
  setup,
};

export default config;

import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2 } from "../../lib-atomic-functions";
import { Inputs, Buttons, Invalid, Valid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const PROMOCODEOBJECT = CONFIG.application.config.objects_promocode;
const BUYNOWENDPOINTS = CONFIG.application.config.api.endpoints;
const PROMOCODEOBJECTCONFIG = {
  url: BUYNOWENDPOINTS.promo.url,
  method: BUYNOWENDPOINTS.promo.method,
  headers: BUYNOWENDPOINTS.promo.headers,
  responseType: "json",
};
const PROMOCODEFAILRESPONSE = CONFIG.application.config.responses.promoFail;
const PROMOCODESUCCESSRESPONSE =
  CONFIG.application.config.responses.promoSuccess;
/* CONSTANTS */

const index = (props) => {
  const [promoCodePayload, promoCodePayloadSet] = useState("");
  const isPromoLS = LocalStorage.getLS().promocode ? true : false;
  const tokenLS = LocalStorage.getLS().login.token;

  PROMOCODEOBJECTCONFIG.headers.Authorization = tokenLS;

  /* state */
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [isPromoCodeSubmit, setIsPromoCodeSubmit] = useState(false);
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- promocode */
  /* -------------- */
  const { promocode } = useContext(GlobalContext);
  const [fetchPromoCode] = FetchContextApiV2(PROMOCODEOBJECTCONFIG);
  const execPromoCodeAdd = async ({ headers, query }) => {
    promocode.dispatch({
      type: promocode.actionTypes.ADD_PROMOCODE,
      // promocode: customPromoCode,
      promocode: await fetchPromoCode(
        { newheaders: headers, query },
        promocode
      ),
    });
  };

  // useEffect(() => {
  //   // fetch local storage and put it inside context
  //   const promoCodeLS = LocalStorage.getLS().promocode;
  //   if (promoCodeLS) {
  //     execPromoCodeAdd({ promoCode: promoCodeLS });
  //   }
  // }, []);
  useEffect(() => {
    if (promocode.state.data.status) {
      LocalStorage.addLS("promocode", {
        ...promocode.state.data.data.json.promos[0],
        promoCode: promoCodePayload,
      });
      setLoadingPromo(false);
      setIsPromoCode(true);
    } else {
      LocalStorage.addLS("promocode", false);
      setLoadingPromo(false);
      setIsPromoCode(false);
    }
  }, [promocode, promoCodePayload, isPromoLS, setIsPromoCode]);

  /* -------------- */
  /* --- promocode */
  /* state */

  const handlePromoCode = async () => {
    setLoadingPromo(true);
    // if (isPromoCode) {
    var body = {
      promoCode: values.promoCode,
    };
    const item = { ...values };
    // execPromoCodeAdd(item);
    onReset(PROMOCODEOBJECT);
    setIsTriggerCreate(true);
    setIsPromoCodeSubmit(true);
    // }
    await execPromoCodeAdd({ query: `promoCode=${values.promoCode}` });
    promoCodePayloadSet(values.promoCode);
  };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handlePromoCode,
    PROMOCODEOBJECT
  );

  const promoCode = {
    ...PROMOCODEOBJECT.promoCode,
    error: error.promoCode,
    value: values.promoCode,
    onChange,
  };
  const submitButton = { size: "large" };
  const failPromo = { error: [PROMOCODEFAILRESPONSE] };
  const successPromo = { error: [PROMOCODESUCCESSRESPONSE] };

  /* data props */
  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Promo Code</b>
            </ModuleSubTitle>
            <form onSubmit={onSubmit} noValidate>
              <ModuleFormWrapper>
                {/* line */}
                <ModuleFormItemWrapper>
                  <Inputs.Text {...promoCode} />
                </ModuleFormItemWrapper>
                {/* line */}
                <ModuleFormItemWrapper>
                  {!isPromoCode && isPromoCodeSubmit && !loadingPromo && (
                    <Fragment>
                      <Invalid {...failPromo} />
                      <br />
                    </Fragment>
                  )}
                </ModuleFormItemWrapper>
                {/* line */}
                <ModuleFormItemWrapper>
                  {isPromoCode && isPromoCodeSubmit && !loadingPromo && (
                    <Fragment>
                      <Typography
                        style={{ color: "#4fd14f" }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        <b>{successPromo.error}</b>
                      </Typography>
                      {/* <Typography
                        style={{ color: "#4fd14f" }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {LocalStorage.getLS().promocode.description}
                      </Typography> */}
                      <br />
                    </Fragment>
                  )}
                </ModuleFormItemWrapper>
                {/* line */}
              </ModuleFormWrapper>
              <ModuleFormWrapper>
                {/* line */}
                <ModuleFormItemWrapper>
                  <Buttons.Normal {...submitButton}>Apply</Buttons.Normal>
                </ModuleFormItemWrapper>
                {/* line */}
              </ModuleFormWrapper>
            </form>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;

import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import Marked from "marked";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Buttons } from "../../lib-atomic";
import { WindowSize } from "../../lib-atomic-functions";
import { ModuleContainerLg } from "../../lib-atomic-design-section";
/* packages */

/* module */
import Loader from "../loader";
/* module */

import CONFIG from "../config";

const index = (props) => {
  /* state */
  /* --- cms */
  /* -------------- */
  const { cms } = useContext(GlobalContext);
  /* -------------- */
  /* --- cms */
  /* state */
  const { width, height } = WindowSize();
  /* data props */
  const homePage = cms.state.data.pages[0];
  const homePage_uat = cms.state.data.pages[10];
  const mediaPath = CONFIG.setup.assets.media;
  const media_01 = homePage_uat.media_01;
  const logo = homePage.media_06;
  const description_01 = {
    __html: homePage.description_01 ? Marked(homePage.description_01) : "",
  };
  // const backgroundImage =
  //   width < 1100 ? "none" : "url(" + mediaPath + media_01.url + ")";
  const backgroundImage =
    "url(" +
    mediaPath +
    media_01.url +
    "), linear-gradient(90deg, rgba(0,0,0,1) 36%, rgba(121,9,9,1) 99%)";
  const backgroundColor = "#000";
  const handleLink = () => {
    props.history.push("/corporate/order");
  };

  const btnData = { size: "large", onClick: handleLink };
  /* data props */
  const fontResizer =
    width > 800 ? "hero size1" : width > 500 ? "hero size2" : "hero size3";
  return (
    <Fragment>
      <div style={{ backgroundImage }} className="heroWrapHome buyNow bg-cover">
        {/* <section className={fontResizer}> */}
        <section className="text-black">
          <ModuleContainerLg>
            <div>
              {/* {width < 1100 ? null : (
                <>
                  <br></br>
                  <br></br>
                  <br></br>
                </>
              )} */}
              {/* {width < 1100 ? (
                <img
                  width={width < 500 ? 160 : 200}
                  alt="westfield"
                  src={mediaPath + logo.url}
                  className="pl-5 pt-5"
                />
              ) : null} */}
              {/* <div
                dangerouslySetInnerHTML={description_01}
                className="pl-5 "
              ></div> */}
            </div>
            {/* <div className="btnWrap">
              <Buttons.Normal {...btnData}>BUY NOW</Buttons.Normal>
            </div> */}
          </ModuleContainerLg>
        </section>
      </div>
    </Fragment>
  );
};

export default index;
